.memeCard {
  margin-top: 10%;
  height: auto;
}

@media (min-width: 1200px) {
  .memeCard {
    margin-top: 0%;
    height: auto;
  }
}

.meme {
  margin: 5%;
  min-height: auto;
  max-height: auto;
  width: 400px;
  max-width: -moz-available; /* WebKit-based browsers will ignore this. */
  max-width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  min-width: stretch;
  max-width: -moz-available; /* WebKit-based browsers will ignore this. */
  min-width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  min-width: stretch;
}

#memeButton {
  margin-top: 5%;
  text-transform: capitalize;
}
